
//The content within the collaspable area
.Collapsible__contentInner {
width: 100%;
margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 0;
  font-weight: normal;
  font-size: 1.5em !important;
  margin: 0 auto;
  
  
  
  
 
  background: transparent;
  color: black !important;
  p {
    margin: 0px;
 
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  width: auto;
  font-size: 2em;
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  margin: 0 auto;
  
  padding: 15px;
  padding-bottom: 0px;
  padding-top: 0px;
  background: transparent;
  color: black !important;
  font-weight: bold;
  border: .3rem solid black;
   border-radius: 2rem;



  &:after {
    content: '+';
    position: absolute;
    right: 10px;
    top: 0px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(45deg);
    }
  }


}

.Collapsible__contentInner_white {
    width: 50%;
    margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;
      border-top: 0;
      font-weight: normal;
      font-size: 1.5em !important;
      margin: 0 auto;
      
      
      
      
     
      background: transparent;
      color: white !important;
      p {
        margin: 0px;
     
        line-height: 20px;
    
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
    //The link which when clicked opens the collapsable area
    .Collapsible__trigger_white {
      width: 50%;
      font-size: 2em;
      display: block;
      font-weight: 400;
      text-decoration: none;
      position: relative;
      
      padding: 15px;
      background: transparent;
      color: white !important;
      font-weight: bold;
      margin: 0 auto;
      padding: 15px;
  padding-bottom: 0px;
  padding-top: 0px;
    
    
      &:after {
        content: '+';
        position: absolute;
        right: 10px;
        top:0px;
        display: block;
        color: white !important;
        transition: transform 300ms;
      }
    
      &.is-open {
        &:after {
          transform: rotateZ(45deg);
          color: white !important;
        }
      }
    
    
    }





@media only screen and (min-width: 700px) and (max-width: 1000px)
{

    .Collapsible__contentInner {
        width: 60%;
    }
    .Collapsible__trigger {
        width: 60%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 700px)
{
    .Collapsible__contentInner {
        width: 80%;
    }
    .Collapsible__trigger {
        width: 80%;
    }
}


