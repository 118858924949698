@import url('https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}

@font-face {
  font-family: "Lazydog";
  src: url("./fonts/lazydog.otf");
}

.trueContainer {
  width: 60%;
  margin: 0 auto;
  /*background-color: blue;*/
  text-align: center;
}


.formContainer {
  width: 40%;
}

* {
  font-family: 'Montserrat';

  color: black;
}

p {
  color:  black;
  opacity: .8;
}

body {
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
}

.pageContainer {
  width: 100%;
  display: inline-block;
  
  padding: 0;
  margin: 0;
  margin: 0 auto;
  min-height: auto;
  height: auto;
  -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
    animation: 3s ease 0s normal forwards 1 fadeIn;
    opacity: 0;

    background-color: white;
  
}

.container {
  width: 100% !important;
  display: flex;

margin: 0 auto;
  justify-content: space-around;
  flex-direction: row;


  background-color: pink;
}

.middle {
  width: 50%;
  margin: 0 auto !important;
}


h1 {
  font-size: 3rem;
  margin: 0px;
}

h4 {
  font-size: 1.5rem;
}

.typeOfUser {
  font-size: 1.2rem;
}

p {
  font-size: 1.5rem;
  margin: 0px;
}

h2 {
  font-size: 1rem;
  margin: 0px;
}

h6 {
  font-size: 1rem;
  margin: 0px;
}


h3 {
  font-size: 2rem;
  text-align: center;
}



.logo {
  width: 80% !important;
  margin: 0 auto;
  margin-top: 2rem;

}

.logo2 {
  width: 50%;
}

.socialContainer {
  margin-top: 1rem;

  width: 50%;
  display: flex;
  margin-bottom: 1rem;
}

.socialItem {
  width: 50%;
 
}

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: #00e6c0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00e6c0;
  --bs-btn-hover-border-color: #00e6c0;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00e6c0;
  --bs-btn-active-border-color: #00e6c0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-width: .2rem;
}

.twoGroupContainer {
  display: inline-block;
  flex-direction: row;
  align-items: stretch;
  margin-top: 2em;
  align-items: center;
  justify-content: center;
}


.group0 {
  width: 40%;
  margin: 0 auto;
  /*background-color: #00e6c0;*/
}

.group1 {
  width: 100%;

}

.group2 {
  width: 75%;
  margin: 0 auto;
  /*background-color: yellow;*/
}

.group3 {

  width: 70%;
  margin: 0 auto;
  
}

.group4 {
  width: 40%;
  margin: 0 auto;
}

@media only screen and (min-width: 700px) and (max-width: 1000px)
{

  .formContainer {
    width: 30%;
  }
   
  .item {
    width: 40%;
  }

  .left {
    display: none;
    width: 25%;
    margin: 0 auto !important;
  }
  .middle {
    width: 100%;
    margin: 0 auto !important;
  }
  .right {
    display: none;
    width: 25%;
    margin: 0 auto !important;
  }


  .logo {
    width: 40%;
    margin-top: 2rem;
  }

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  
  
  
  h1 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }
 
  p {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1rem;
  }



  .trueContainer {
    width: 80%;

  }

  .group0 {
    width: 60%;
    margin: 0 auto;
    /*background-color: #00e6c0;*/
  }
  
  .group1 {
    width: 100%;
  
  }
  
  .group2 {
    width: 75%;
    margin: 0 auto;
    /*background-color: yellow;*/
  }
  
  .group3 {
  
    width: 100%;
    margin: 0 auto;
    
  }
  
  .group4 {
    width: 80%;
    margin: 0 auto;
  }

}

@media only screen and (min-width: 0px) and (max-width: 700px)
{
  .group4 {
    width: 100%;
    margin: 0 auto;
  }

  .group0 {
    width: 80%;
    margin: 0 auto;
    /*background-color: #00e6c0;*/
  }
  
  .group1 {
    width: 100%;
  
  }
  
  .group2 {
    width: 75%;
    margin: 0 auto;
    /*background-color: yellow;*/
  }
  
  .group3 {
  
    width: 100%;
    margin: 0 auto;
    
  }

  .trueContainer {
    width: 90%;

  }



  .formContainer {
    width: 60%;
  }

  .item {
    width: 90%;
  }

  .left {
    display: none;
    width: 25%;
    margin: 0 auto !important;
  }
  .middle {
    width: 100%;
    margin: 0 auto !important;
  }
  .right {
    display: none;
    width: 25%;
    margin: 0 auto !important;
  }

  .logo {
    width: 40%;
    margin-top: 2rem;
  }
  
  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  
  
  
  h1 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }
 
  p {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1rem;
  }
  
}
